import { Avatar } from 'src/components/Display/Avatar'
import { CSSTransition } from 'react-transition-group'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { Icon } from 'src/components/Icon'
import { Link } from 'src/components/Link'
import { NotificationTooltip } from 'src/components/NotificationTooltip'
import { StackedPaper } from 'src/components/StackedPaper'
import { useMember } from 'src/providers/MemberProvider'
import { useUiState } from 'src/hooks/useStores'

export const CollectionCard = ({
  className,
  author = 'self',
  displayTooltip = true,
  id,
  isFavorites,
  isFollowedCollection = false,
  isFollowedBySelf = false,
  isPrivate,
  name,
  numUpdates = 0,
  numVideos,
  thumbnailImage,
}) => {
  const { currentMember, isSelf, username } = useMember()
  const { setBookmarkCollections } = useUiState()

  const [toggleTooltip, setToggleTooltip] = React.useState(displayTooltip)
  React.useEffect(() => {
    setTimeout(() => setToggleTooltip(false), 5000)
  }, [])

  const getRoute = React.useCallback(() => {
    if (!id) return `/${username || 'profile'}/collections`

    if (isFavorites && !isFollowedBySelf) return `/${username || 'profile'}/collections/favorites`

    if (isFollowedBySelf) return `/${username || 'profile'}/collections/${id}`

    return `/${author.username || username || 'profile'}/collections/${id}`
  }, [author, id, isFavorites, isFollowedBySelf, username])

  const route = React.useMemo(() => getRoute(), [getRoute])

  const setBookmarkStateHandler = React.useCallback(() => {
    if (isFollowedCollection && !isFollowedBySelf) {
      setBookmarkCollections({ userFollowingCollection: currentMember })
    }
  }, [isFollowedCollection, isFollowedBySelf, currentMember, setBookmarkCollections])

  const getTitleLength = React.useCallback(() => {
    if (name?.length < 12) {
      return 'default'
    } else if (name?.length >= 12) {
      return 'large'
    }
  }, [name])

  const getTitleWidth = React.useCallback(() => {
    if (name?.length >= 12 && isFollowedCollection) {
      return '80%'
    } else {
      return 'content-fit'
    }
  }, [isFollowedCollection, name])

  const getTooltipCopy = React.useCallback(() => {
    if (isFollowedBySelf) {
      return numUpdates > 1 ? 'New Updates!' : 'New Update!'
    } else {
      return numUpdates > 1 ? 'New Followers!' : 'New Follower!'
    }
  }, [isFollowedBySelf, numUpdates])

  const titleLength = React.useMemo(() => getTitleLength(), [getTitleLength])
  const titleWidth = React.useMemo(() => getTitleWidth(), [getTitleWidth])
  const tooltipCopy = React.useMemo(() => getTooltipCopy(), [getTooltipCopy])

  return (
    <Styles.Container
      className={className}
      isFollowedCollection={isFollowedCollection}
      onClick={setBookmarkStateHandler}
      to={route}
    >
      <Styles.Paper>
        {isPrivate && <Styles.PrivacyIcon />}
        <Styles.ImageContainer isFollowedCollection={isFollowedCollection}>
          <Styles.Image alt={`${name} collection`} height={160} src={thumbnailImage} width={230} />
        </Styles.ImageContainer>
        <Styles.TitleContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Styles.Title
              isFavorites={isFavorites}
              isFollowedCollection={isFollowedCollection}
              width={titleWidth}
            >
              {name}
            </Styles.Title>
          </div>
          <Styles.CollectionMetadataContainer>
            <Styles.Subtitle>
              {numVideos} video{numVideos !== 1 && 's'}
            </Styles.Subtitle>
            {isFollowedCollection && (
              <Styles.Subtitle isAuthorName={true}>
                by{' '}
                {getMemberDisplayName({ profile: { ...author } }, { useFullNameWithInitial: true })}
              </Styles.Subtitle>
            )}
          </Styles.CollectionMetadataContainer>
        </Styles.TitleContainer>
        {isFollowedCollection && (
          <Styles.AvatarContainer>
            <Styles.Avatar
              avatarUrl={author?.avatarUrl}
              firstName={author?.firstName}
              foundingMember={false}
              lastName={author?.lastName}
            />
          </Styles.AvatarContainer>
        )}
        {isSelf && numUpdates > 0 && (
          <Styles.NotificationContainer
            isFollowedCollection={isFollowedCollection}
            isLongTitle={titleLength === 'large'}
          >
            <CSSTransition
              classNames='fade'
              in={toggleTooltip}
              timeout={300}
              appear={toggleTooltip}
              unmountOnExit
            >
              <Styles.TooltipContainer>
                <Styles.Tooltip
                  displayTooltip={displayTooltip || toggleTooltip}
                  content={tooltipCopy}
                  direction='bottom'
                  isFollowedCollection={isFollowedCollection}
                  showDismiss={false}
                />
              </Styles.TooltipContainer>
            </CSSTransition>
            <Styles.NotificationDot
              isFollowedCollection={isFollowedCollection}
              onMouseEnter={() => setToggleTooltip(true)}
              onMouseLeave={() => setToggleTooltip(false)}
            >
              <div>{numUpdates}</div>
            </Styles.NotificationDot>
          </Styles.NotificationContainer>
        )}
      </Styles.Paper>
    </Styles.Container>
  )
}

const Styles = {
  NotificationContainer: styled.div`
    bottom: 0px;
    z-index: 10;
    right: 0px;
  `,
  TooltipContainer: styled.div`
    transition: opacity 300ms;
    position: absolute;
    bottom: 0.8px;
    z-index: 10;

    &.fade-enter {
      opacity: 0;
    }

    &.fade-enter-active {
      opacity: 1;
    }

    &.fade-exit {
      opacity: 0;
    }
  `,
  Tooltip: styled(NotificationTooltip)` 
    position: absolute;
    left: 199px;
    bottom: 42px;
    white-space: nowrap;
    width: 'fit-content'
    height: 50px;
    border-radius: 10px;
    border: 2px solid var(--persianBlue);
    z-index: 10;

    div {
      font-weight: 400;
      font-size: 16px;
      line-height: 16.8px;
    }

    ${({ displayTooltip }) =>
      !displayTooltip &&
      css`
        display: none;
      `}
  `,
  NotificationDot: styled.div`
    position: absolute;
    bottom: 10px;
    height: 18px;
    width: 18px;
    right: 10px;
    z-index: 10;
    background-color: var(--persianBlue);
    color: white;
    text-align: center;
    font-size: 10px;
    border-radius: 50%;

    div {
      margin-top: 1.4px;
      margin-right: 0.7px;
    }
  `,
  CollectionMetadataContainer: styled.div`
    position: absolute;
    bottom: 7px;
  `,
  Container: styled(Link)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 12px;
    height: 260px;
    width: 230px;

    ${({ isFollowedCollection }) =>
      isFollowedCollection &&
      css`
        height: 270px;
      `}

    :hover {
      cursor: pointer;
      text-decoration: none;
    }
  `,
  Paper: styled(StackedPaper)`
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    z-index: unset;
  `,
  PrivacyIcon: styled(Icon.Lock)`
    position: absolute;
    top: 9px;
    right: 10px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    height: 67%;

    ${({ isFollowedCollection }) =>
      isFollowedCollection &&
      css`
        height: 60%;
      `}
  `,
  Image: styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,
  TitleContainer: styled.div`
    width: 100%;
    padding: 10px 15px;
  `,
  Title: styled.div`
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 23px;
    width: ${(props) => props.width};

    ${({ isFavorites }) =>
      isFavorites &&
      css`
        text-transform: capitalize;
      `}
  `,
  Subtitle: styled.h5`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 15.4px;
    letter-spacing: 0.4px;

    ${({ isAuthorName }) =>
      isAuthorName &&
      css`
        font-style: italic;
        line-height: 13.2px;
      `}

    ${({ addMarginTop }) =>
      addMarginTop &&
      css`
        margin-top: 18px;
      `}
  `,
  AvatarContainer: styled.div`
    position: absolute;
    right: -65px;
    top: 130px;
    z-index: 9;
  `,
  Avatar: styled(Avatar)`
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    font-size: 14px;
    border-radius: 50%;
    border: 2px solid white;
  `,
}
