import { Dismiss } from 'src/components/Display/Dismiss'
import { updateConditionalDisplay } from 'src/models/user'
import { useAsyncCallback } from 'react-async-hook'
import { UserAction } from 'src/components/UserAction'
import { useUser, setAccount } from 'src/hooks/useUser'

const tooltipMargin = 10

export const NotificationTooltip = ({
  arrowPosition = 50,
  children,
  className,
  conditionalDisplayAttribute,
  content,
  direction = 'top',
  isStreamOff,
  onAcknowledge = Function.prototype,
  onShowTooltip = Function.prototype,
  showDismiss = true,
}) => {
  const { currentUser } = useUser()
  const shouldRender =
    currentUser?.account?.conditionalDisplayAttributes?.[conditionalDisplayAttribute] !== false

  const [showTooltip, setShowTooltip] = React.useState(shouldRender)
  const [dimensions, setDimensions] = React.useState({ height: undefined, width: undefined })

  const tooltipRef = React.useRef(null)

  const { execute: onClose } = useAsyncCallback(async () => {
    setShowTooltip(false)
    if (typeof onAcknowledge === 'function') onAcknowledge()

    if (conditionalDisplayAttribute) {
      const { data } = await updateConditionalDisplay(currentUser?.id, {
        [conditionalDisplayAttribute]: false,
      })

      if (data?.account) setAccount(data.account)
    }
  })

  React.useEffect(() => {
    if (tooltipRef?.current) {
      const { offsetHeight, offsetWidth } = tooltipRef.current

      setDimensions({
        height: offsetHeight,
        width: offsetWidth,
      })
    }
  }, [tooltipRef])

  React.useEffect(() => {
    if (showTooltip && typeof onShowTooltip === 'function') onShowTooltip()
  }, [showTooltip])

  return (
    <Styles.Wrapper>
      {children}
      {showTooltip && (
        <Styles.Tooltip
          arrowPosition={arrowPosition}
          className={className}
          direction={direction}
          height={dimensions.height}
          ref={tooltipRef}
          width={dimensions.width}
        >
          <Styles.ContentContainer>
            <Styles.Content isStreamOff={isStreamOff} showDismiss={showDismiss}>
              {content}
            </Styles.Content>
            {showDismiss && (
              <Styles.Dismiss onClick={onClose}>
                <Styles.DismissIcon />
              </Styles.Dismiss>
            )}
          </Styles.ContentContainer>
        </Styles.Tooltip>
      )}
    </Styles.Wrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    position: relative;
    width: fit-content;
  `,
  Tooltip: styled.div`
    position: absolute;
    z-index: 10000;

    background-color: white;
    border: 2px solid var(--persianBlue);
    min-width: 100px;
    min-height: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    color: var(--persianBlue);

    ${({ direction }) =>
      direction === 'top' &&
      css`
        transform: translateX(-50%);
      `}

    ${({ direction }) =>
      direction === 'right' &&
      css`
        transform: translateY(-50%);
      `}

    ${({ direction }) =>
      direction === 'bottom' &&
      css`
        transform: translateX(-50%);
      `}

    ${({ direction, width }) =>
      direction === 'left' &&
      css`
        left: calc((${width}px + ${tooltipMargin}px) * -1);
        top: 50%;
        transform: translateY(-50%);
      `}

    ::before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 12px solid transparent;

      ${({ direction, arrowPosition }) =>
        direction === 'top' &&
        css`
          left: ${arrowPosition}%;
          bottom: 100%;
          transform: translateX(-50%);
          border-bottom-color: var(--persianBlue);
        `}

      ${({ direction }) =>
        direction === 'right' &&
        css`
          right: 100%;
          top: 50%;
          transform: translateY(-50%);
          border-right-color: var(--persianBlue);
        `}

      ${({ direction, arrowPosition }) =>
        direction === 'bottom' &&
        css`
          left: ${arrowPosition}%;
          top: 100%;
          transform: translateX(-50%);
          border-top-color: var(--persianBlue);
        `}

      ${({ direction }) =>
        direction === 'left' &&
        css`
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
          border-left-color: var(--persianBlue);
        `}
    }

    ::after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 9px solid transparent;

      ${({ direction, arrowPosition }) =>
        direction === 'top' &&
        css`
          bottom: 100%;
          left: ${arrowPosition}%;
          transform: translateX(-50%);
          border-bottom-color: white;
        `}

      ${({ direction }) =>
        direction === 'left' &&
        css`
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
          border-left-color: white;
        `}

      ${({ direction, arrowPosition }) =>
        direction === 'bottom' &&
        css`
          left: ${arrowPosition}%;
          top: 100%;
          transform: translateX(-50%);
          border-top-color: white;
        `}

      ${({ direction }) =>
        direction === 'right' &&
        css`
          right: 100%;
          top: 50%;
          transform: translateY(-50%);
          border-right-color: white;
        `}
    }
  `,
  ContentContainer: styled.div`
    display: flex;
    position: relative;
  `,
  Content: styled.div`
    padding: 14px 4px 14px 14px;

    ${({ showDismiss }) =>
      !showDismiss &&
      css`
        padding-right: 14px;
      `}

    ${({ isStreamOff }) =>
      isStreamOff &&
      css`
        padding: 10px 0px 10px 14px;
      `}
  `,
  Dismiss: styled(UserAction)`
    display: flex;
    height: fit-content;
    padding: 2.5px;
    margin: 2px;
  `,
  DismissIcon: styled(Dismiss)`
    width: 10px;
    height: 10px;
  `,
}
